<template>
  <div class="sender-container">
    <div class="box">
      <div>
        <svg xmlns="http://www.w3.org/2000/svg" width="80" height="80" fill="#4ea934" viewBox="0 0 256 256"><path d="M243.31,90.91l-128.4,128.4a16,16,0,0,1-22.62,0l-71.62-72a16,16,0,0,1,0-22.61l20-20a16,16,0,0,1,22.58,0L104,144.22l96.76-95.57a16,16,0,0,1,22.59,0l19.95,19.54A16,16,0,0,1,243.31,90.91Z"></path></svg>
        <h1>Remetente verificado com sucesso</h1>
        <p>
          Agora você poderá enviar seus e-mails para sua base de leads!
        </p>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  data(){
    return{
    }
  }
}
</script>


<style lang="scss" scoped>
  .sender-container {
    background-color: var(--greenn2);
    height: 100vh;
    display: grid;
    place-items: center;
    background: linear-gradient(315deg, color-mix(in lch, var(--bluee) 70%, #0000), color-mix(in lch, var(--greenn) 70%, #0000)) !important;
    max-width: 100vw;
    overflow: hidden;
    &::after, &::before {
      content: '';
      height: 1000px;
      width: 1000px;
      background-image: linear-gradient(90deg, transparent, var(--greenn));
      border-radius: 1500px;
      position: absolute;
      left: calc(45%);
      z-index: -1;
      animation: wave 16s cubic-bezier(0, 1, 1, 0) alternate infinite;
      margin-top: -250px;
    }
    &::before {
      animation: wave 16s cubic-bezier(0, 1, 1, 0) alternate-reverse infinite;
      background-image: linear-gradient(90deg, var(--bluee), transparent);
      left: unset;
      right: calc(45%);
      margin-top: 250px;
    }
    @keyframes wave {
      0% {
        transform: rotate(0deg);
        top: calc(100vh + 2500px);
        opacity: .5;
      }
      100% {
        transform: rotate(750deg);
        top: -3500px;
        opacity: 0;
      }
    }
  }
  .box {
    max-width: 450px;
    width: 80%;
    height: 420px;
    border-radius: 15px;
    background-color: white;
    padding: 40px;
    text-align: center;
    animation: expand .2s forwards ease-in-out;
    display: grid;
    place-items: center;
    h1, p {
      font-weight: 600;
      color: var(--gray05);
      margin-top: 30px;
    }

    h1{
      font-size: 24px;
    }

    p{
      font-size: 16px;
    }
    @keyframes expand {
      from {
        transform: scale(1.2);
      }
      to{
        transform: scale(1);
      }
    }
  }

</style>
